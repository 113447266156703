<template>
  <div>
    <div>
        <div class="flexed justify-center align-center" v-if="loading">
        <loading></loading>
      </div>
      <div v-else class="breakup-container">
        <div v-for="(rate, index) in breakdown_data" :key="index">
          <div class="bold m-t-10">{{ rate.date | formatDate }}</div>
          <div class="breakup-taxes">
            <div
              class="p-5"
              v-for="(breakdown, index) in rate.breakdown"
              :key="index"
            >
              <div class="flexed justify-between">
                <span class="bold600">{{ breakdown.name }} </span>
                <span class="value bold600"
                  >{{ breakdown.amount | currency }}
                </span>
              </div>
              <div
                class="charge-taxes flexed justify-between"
                v-for="(tax, taxIndex) in breakdown.taxes"
                :key="taxIndex"
              >
                <span>{{ tax.name }}</span>
                <span class="value">{{
                  tax.amount | currency
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import commonServices from "../../../services/common.services";
import dayJs from "dayjs";
import { differenceInCalendarDays, addDays, formatISO } from "date-fns";
import loading from '@/components/common/loading.vue';

export default {
  name: "BreakdownPopup",
  props: ["rateModalData"],
  components: {loading},
  data() {
    return {
      loading: false,
      breakdown_data: null,
    };
  },
  computed: {
    ...mapState({
      children_ages: (state) => state.children_ages,
      queryParamsInfoState: (state) => state.queryParamsInfoState,
    }),
  },
  methods: {
    async getBreakdownData() {
      let payload = {
        rate_code_id: this.rateModalData?.rateDetails?.id,
        number_of_persons: 1,
        number_of_children: 0,
        children_ages: this.children_ages,
        rates: [],
      };

      const checkInDate = new Date(this.queryParamsInfoState.check_in_date);
      const checkOutDate = new Date(this.queryParamsInfoState.check_out_date);
      const amountPerNight = this.rateModalData?.rateDetails?.rate_amount;

      const numberOfNights = differenceInCalendarDays(
        checkOutDate,
        checkInDate
      );

      for (let i = 0; i < numberOfNights; i++) {
        const date = addDays(checkInDate, i);
        payload.rates.push({
          date: formatISO(date, { representation: "date" }) + "T00:00:00.000Z",
          amount: amountPerNight,
        });
      }
      try {
        this.loading = true;
        const res = await commonServices.postBreakdown(
          payload,
          this.rateModalData?.rateDetails?.package_id
        );
        this.breakdown_data = res.data?.rates;
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
  },
  beforeMount() {
    console.log("rate modal", this.rateModalData);
    this.getBreakdownData();
  },
};
</script>

<style scoped>

.breakup-container {
    max-height: 500px;
    overflow: auto;
}
</style>
