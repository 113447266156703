<template>
    <div>
        <div class="price-details">
            <div class="flexed justify-between">
                <strong class="m-b-10">{{ $t("lbl_price_details") }}</strong>
                <span @click="showBreakdownPopup = true" class="pointer">Show Breakdown Details</span>   
            </div>
              <h5 class="m-t-10 m-b-10">{{ $t("lbl_price_for") }} {{ numberOfRooms }}, {{ numberOfPersons }}, {{ calculateStayDuration }} </h5>
              <div class="flexed justify-between m-b-10 w-100">
                    <span>{{ $t("lbl_room_subtotal") }}</span>
                  <template>
                      <div v-if="ifRateCodeParamIsGoogle">
                          {{ rateModalData.price_summary.total_amount_after_tax | currency }}
                      </div>
                      <div v-else-if="rateModalData.price_summary?.total_points && enable_book_with_points"
                          class="flexed align-center justify-end" :class="{ 'justify-end': mobileView }">
                          <div class="price sub-title">{{ rateModalData.price_summary.total_points | formatBigNumber
                          }} {{ $t("lbl_points") }}</div>
                      </div>
                      <div v-else-if="discount_applied"
                          class="flexed align-center" :class="{ 'justify-end': mobileView }">
                          <!-- <div class="discount-price  m-r-5" :class="{ 'flex_1': !mobileView }">
                              {{ (rateModalData.price_summary.avg_base_amount_before_tax ?
                                  rateModalData.price_summary.avg_base_amount_before_tax :
                                  rateModalData.price_summary.avg_amount_before_tax +
                                  (rateModalData.price_summary.avg_inclusive_tax_amount || 0)) | currency }}
                          </div> -->
                          <div class="price sub-title">{{ rateModalData.price_summary.conversion_total_amount_after_discount ? rateModalData.price_summary.conversion_total_amount_after_discount : rateModalData.price_summary.total_amount_after_discount | currency }}</div>
                      </div>
                      <div v-else :class="['price', 'sub-title']">
                          <!--                  {{ rateModalData.price_summary.avg_amount_before_tax | currency }}-->
                          <!-- <div v-if="rateModalData.price_summary.conversion_avg_amount_before_tax">
                            {{ (rateModalData.price_summary.conversion_avg_amount_before_tax +
                              (rateModalData.price_summary.conversion_avg_inclusive_tax_amount || 0)) | currency }}
                          </div>
                          <div v-else>
                          {{ (rateModalData.price_summary.avg_amount_before_tax +
                              (rateModalData.price_summary.avg_inclusive_tax_amount || 0)) | currency }}
                          </div> -->
                          {{ (rateModalData.price_summary.conversion_total_amount || rateModalData.price_summary.total_amount) | currency }}
                      </div>
              </template>
              </div>
              <div class="flexed justify-between border-top m-b-10">
                  <span>{{ $t("lbl_taxes") }}</span>
                  <span v-if="discount_applied">{{ calculateTax | currency}}</span>
                  <span v-else>{{ (rateModalData.price_summary.conversion_total_tax_amount || rateModalData.price_summary.total_tax_amount) | currency }}</span>
              </div>
              <div class="flexed justify-between border-top m-b-10">
                  <span>{{ $t("lbl_fees") }}</span>
                  <span>{{ (rateModalData.price_summary.conversion_total_fee_amount || rateModalData.price_summary.total_fee_amount) | currency }}</span>
              </div>
              <div class="flexed justify-between border-top">
                  <strong>{{ $t("lbl_estimated_total_in_selected_currency") }}</strong>
                  <span v-if="discount_applied">{{ calculateEstimatedTotalWhenDiscount | currency}}</span>
                  <span v-else>{{( rateModalData.price_summary.conversion_total_amount_after_tax_and_fee || rateModalData.price_summary.total_amount_after_tax_and_fee) | currency}}</span>
              </div>
              <div class="m-t-10 flexed justify-between border-top">
                  <strong>{{ $t("lbl_estimated_total_in_property_currency") }}</strong>
                  <span v-if="discount_applied">{{ calculateEstimatedTotalWhenDiscount | currency(currencyCode)}}</span>
                  <span v-else>{{ rateModalData.price_summary.total_amount_after_tax_and_fee | currency(currencyCode)}}</span>
              </div>
              <div class="m-t-20 flexed justify-between">
                  <span>{{ $t("lbl_final_payment_will_be_in_the_hotels_currency") }}</span>
              </div>
          </div>


        <div v-if="!mobileView && rateModalData.additional_details" style="padding-top: 6px; font-weight: 400;" class="description m-t-20">
            <strong>{{ $t("lbl_rate_plan_description") }}</strong>
            <div class="m-t-0 list-desc" >
                <div v-html="renderMarkdown(rateModalData.additional_details)"></div>
            </div>
        </div>

        <div v-if="!mobileView && rateModalData.package_description" style="padding-top: 6px; font-weight: 400;" class="description m-t-20">
            <strong>{{ $t("lbl_package_description") }}</strong>
            <div class="m-t-0 list-desc" >
              <div v-if="rateModalData.package_description"
                   v-html="renderMarkdown(rateModalData.package_description)"></div>
            </div>
        </div>

        <div>
          <strong>{{ $t("lbl_cancellation_policy") }}</strong>
          <p class="m-t-0">{{ rateModalData.cancellation_policy || ''}}</p>
      </div>
      <modal
        v-if="showBreakdownPopup"
        size="45%"
        @close="showBreakdownPopup = false"
        style="margin-bottom: 50px;"
        >
        <div slot="modal-title"> <h3 class="m-t-0 m-b-0">{{ rateModalData.rateTitle }}</h3> </div>
        <div slot="content">
            <BreakdownPopup :rateModalData="rateModalData"></BreakdownPopup>
        </div>
        <div slot="modal-footer">
            <el-button type="danger" @click="showBreakdownPopup = false">{{ $t("lbl_close") }}</el-button>
        </div>
    </modal>
    </div>
</template>

<script>
import { mapState } from 'vuex';
import {marked} from 'marked'
import dayJs from "dayjs";
import BreakdownPopup from './BreakdownPopup.vue';
import Modal from "@/components/Modal.vue";



export default {
    name: 'PriceDetailsPopup',
    props: ['rateModalData'],
    components: {BreakdownPopup, Modal},
    data() {
        return {
            showBreakdownPopup: false
        }
    },
    computed: {
        ...mapState({
            property: state => state.property.details,
            queryParamsInfoState: state => state.queryParamsInfoState,
            mobileView: state => state.mobileView,
            enable_book_with_points: state => state.enable_book_with_points,
            discount_applied: state => state.discount_applied
        }),
        calculateStayDuration() {
            const diff = dayJs(this.queryParamsInfoState?.check_out_date).diff(dayJs(this.queryParamsInfoState?.check_in_date), 'day')
            return `${diff} ${diff > 1 ? `${this.$t('lbl_nights')}` : `${this.$t('lbl_night')}`}`
        },
        numberOfRooms() {
            return `${this.queryParamsInfoState?.number_of_rooms} ${this.queryParamsInfoState?.number_of_rooms > 1 ? `${this.$t('lbl_rooms')}` : `${this.$t('lbl_room')}`}`
        },
        numberOfPersons() {
            return `${this.queryParamsInfoState?.number_of_persons} ${this.queryParamsInfoState?.number_of_persons > 1 ? `${this.$t('lbl_adults')}` : `${this.$t('lbl_adult')}`}`
        },
        numberOfNights() {
            return dayJs(this.queryParamsInfoState?.check_out_date).diff(dayJs(this.queryParamsInfoState?.check_in_date), 'day')
        },
        currencyCode() {
            return this.property_currency || 'USD'
        },
        calculateTax() {
            if(this.rateModalData?.price_summary?.conversion_avg_net_amount_after_discount && this.rateModalData?.price_summary?.conversion_avg_amount_after_discount) {
                return (this.rateModalData?.price_summary?.conversion_avg_net_amount_after_discount * this.numberOfNights) - (this.rateModalData?.price_summary?.conversion_avg_amount_after_discount * this.numberOfNights)
            }
           return (this.rateModalData?.price_summary?.avg_net_amount_after_discount * this.numberOfNights) - (this.rateModalData?.price_summary?.avg_amount_after_discount * this.numberOfNights)
        },
        ifRateCodeParamIsGoogle(){
            return this.$route.query?.rate_code === 'GOOGLE'
        },
        calculateEstimatedTotalWhenDiscount() {
            if(this.rateModalData.price_summary?.conversion_total_fee_amount && this.rateModalData.price_summary?.conversion_total_amount_after_discount) {
                return this.calculateTax + this.rateModalData.price_summary?.total_fee_amount + this.rateModalData.price_summary?.total_amount_after_discount
            }
            return this.calculateTax + this.rateModalData.price_summary?.total_fee_amount + this.rateModalData.price_summary?.total_amount_after_discount
        }
    },
    methods: {
        renderMarkdown(markedText) {
        if (markedText) {
          return marked(markedText, {sanitize: true})
        }
        return '';
      },
    }
}
</script>

<style scoped>
.discount-price {
    font-size: 10px;
    margin-right: 10px;
    text-decoration: line-through red;
}

.sub-title {
    font-size: 16px;
    font-weight: bold;
    color: #4E4E4E;
}

:deep(.list-desc ul) {
    margin-bottom: 20px;
    margin-left: 30px !important;
    list-style: disc !important;
}

:deep(.description p) {
    margin-top: 0px;
}




</style>
